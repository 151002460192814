// TREE LAYOUT ENGINE FUNCTIONS

// A recursive helper function for performing some setup by walking through all nodes
function visit(parent, visit_fn, children_fn) {
    if (!parent) return;

    visit_fn(parent);
    node_dict[parent.name] = parent;
    var children = children_fn(parent);
    if (children) {
        var count = children.length;
        for (var i = 0; i < count; i++) {
            visit(children[i], visit_fn, children_fn);
        }
    }
}

// Define the zoom function for the zoomable tree
function zoom() {
    svgGroup.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
}


// Helper functions for collapsing and expanding nodes.

function collapse(d) {
    if (d.children) {
        d._children = d.children;
        d._children.forEach(collapse);
        d.children = null;
    }
}

function expand(d) {
    if (d._children) {
        d.children = d._children;
        d.children.forEach(expand);
        d._children = null;
    }
}

// Function to center node when clicked so node doesn't get lost when collapsing/moving with large amount of children.
function center_node(source) {
    scale = zoomListener.scale();
    x = -source.y0;
    y = -source.x0;
    x = x * scale + viewer_width / 2;
    y = y * scale + viewer_height / 2;
    d3.select('g').transition()
        .duration(duration)
        .attr("transform", "translate(" + x + "," + y + ")scale(" + scale + ")");
    zoomListener.scale(scale);
    zoomListener.translate([x, y]);
}

// Toggle children function
function toggle_children(d) {
    if (d.children) {
        d._children = d.children;
        d.children = null;
    } else if (d._children) {
        d.children = d._children;
        d._children = null;
    }
    return d;
}

// Toggle children on click.
function click(d) {
    if (d3.event.defaultPrevented) return; // click suppressed
    d = toggle_children(d);
    update(d);
    center_node(d);
    // ga("send", "event", "button", "click", "Toggle")

}

function update(source) {
    // Compute the new height, function counts total children of root node and sets tree height accordingly.
    // This prevents the layout looking squashed when new nodes are made visible or looking sparse when nodes are removed
    // This makes the layout more consistent.
    var level_width = [1];
    var child_count = function(level, n) {

        if (n.children && n.children.length > 0) {
            if (level_width.length <= level + 1) level_width.push(0);

            level_width[level + 1] += n.children.length;
            n.children.forEach(function(d) {
                child_count(level + 1, d);
            });
        }
    };
    child_count(0, root);
    var new_height = d3.max(level_width) * 25; // 25 pixels per line
    tree = tree.size([new_height, viewer_width]);

    // Compute the new tree layout.
    var nodes = tree.nodes(root).reverse(),
        links = tree.links(nodes);

    // Set widths between levels based on max_label_length.
    nodes.forEach(function(d) {
        d.y = (d.depth * (max_label_length * 10)); //max_label_length * 10px
        // alternatively to keep a fixed scale one can set a fixed depth per level
        // Normalize for fixed-depth by commenting out below line
        // d.y = (d.depth * 500); //500px per level.
    });

    // Update the nodes…
    node = svgGroup.selectAll("g.node")
        .data(nodes, function(d) {
            return d.id || (d.id = ++i);
        });

    // Enter any new nodes at the parent's previous position.
    var node_enter = node.enter().append("g")
        .attr("class", "node")
        .attr("name", function(d){return d.name})
        .attr("transform", function(d) {
            return "translate(" + source.y0 + "," + source.x0 + ")";
        })
        .on('click', click);

    node_enter.append("circle")
        .attr('class', 'nodeCircle')
        .attr("r", 0)
        .style("fill", function(d) {
            return d._children ? "lightsteelblue" : "#fff";
        });

    node_enter.append("text")
        .attr("x", function(d) {
            return d.children || d._children ? -10 : 10;
        })
        .attr("dy", ".35em")
        .attr('class', 'nodeText')
        .attr("text-anchor", function(d) {
            return d.children || d._children ? "end" : "start";
        })
        .text(function(d) {
            return d.name;
        })
        .style("fill-opacity", 0);

    // Update the text to reflect whether node has children or not.
    node.select('text')
        .attr("x", function(d) {
            return d.children || d._children ? -10 : 10;
        })
        .attr("text-anchor", function(d) {
            return d.children || d._children ? "end" : "start";
        })
        .text(function(d) {
            return d.name;
        });

    // Change the circle fill depending on whether it has children and is collapsed
    node.select("circle.nodeCircle")
        .attr("r", 4.5)
        .style("fill", function(d) {
            return d._children ? "lightsteelblue" : "#fff";
        });

    // Transition nodes to their new position.
    var node_update = node.transition()
        .duration(duration)
        .attr("transform", function(d) {
            return "translate(" + d.y + "," + d.x + ")";
        });

    // Fade the text in
    node_update.select("text")
        .style("fill-opacity", 1);

    // Transition exiting nodes to the parent's new position.
    var node_exit = node.exit().transition()
        .duration(duration)
        .attr("transform", function(d) {
            return "translate(" + source.y + "," + source.x + ")";
        })
        .remove();

    node_exit.select("circle")
        .attr("r", 0);

    node_exit.select("text")
        .style("fill-opacity", 0);

    // Update the links…
    var link = svgGroup.selectAll("path.link")
        .data(links, function(d) {
            return d.target.id;
        });

    // Enter any new links at the parent's previous position.
    link.enter().insert("path", "g")
        .attr("class", "link")
        .attr("d", function(d) {
            var o = {
                x: source.x0,
                y: source.y0
            };
            return diagonal({
                source: o,
                target: o
            });
        });

    // Transition links to their new position.
    link.transition()
        .duration(duration)
        .attr("d", diagonal);

    // Transition exiting nodes to the parent's new position.
    link.exit().transition()
        .duration(duration)
        .attr("d", function(d) {
            var o = {
                x: source.x,
                y: source.y
            };
            return diagonal({
                source: o,
                target: o
            });
        })
        .remove();

    // Stash the old positions for transition.
    nodes.forEach(function(d) {
        d.x0 = d.x;
        d.y0 = d.y;
    });
}

// Init function to expand the first tree levels and
// center the tree in the middle of the screen
function init(){
    root.children.forEach(function(d){
        d.children.forEach(function(d)
        {
            d.children.forEach(function(d){
                collapse(d)
            });
        });
    });
    update(root);
    center_node(node_dict['L0k'])
};


// TREE HELPER FUNCTIONS

// Recursive function to expand all the parents of a given child.
function expand_parent(node){
    if (node._children){
        node.children = node._children
        node._children = null
    }
    if ((node.parent) && (node.parent._children)) {
        expand_parent(node.parent);
    }
};

// Wraps expand_parent to init the recursion and redraw the tree after
// recursion exits.
function expand_parents(node){
    if (node.parent){
        expand_parent(node);
    }
    update(node);
    center_node(node);

}

// A function to expand all the children of a node, also ones depending by
// already expanded parents.
function expand_independently(node){
    if (node._children && node._children != null){
        node.children = node._children;
        node._children = null
    }
    if (node.children){
        node.children.forEach(expand_independently)
    }
}

// Wraps expand_independently to init recursion and redraw the tree after recursion
// exits.
function expand_everything(node){
    expand_independently(node);
    update(node);
    center_node(node);
}

// Resize svg according to window resize
function window_update(){
    x = $(window).width() || e.clientWidth
    y = $(window).height() || e.clientHeight

    baseSvg.attr("width", x).attr("height", y)
}

// MODALS

// Modals show/hide controls
$("#expand").on("click", function(){
    $("#expand_modal").modal("show")
    // ga("send", "pageview", {
    //     "page" : "/expand",
    //     "title" : "Expand all tree"
    // });
});

$("#reset").on("click", function(){
    $("#reset_modal").modal("show")
    // ga("send", "pageview", {
    //     "page" : "/reset",
    //     "title" : "Reset tree"
    // });
});

$("#help").on("click", function(){
    $("#help_modal").modal("show")
    // ga("send", "pageview", {
    //     "page" : "/help",
    //     "title" : "PhyloD3 Help"
    // });
});

$("#about").on("click", function(){
    $("#about_modal").modal("show")
    // ga("send", "pageview", {
    //     "page" : "/about",
    //     "title" : "PhyloD3 About"
    // });
});

$("#credits").on("click", function(){
    $("#credits_modal").modal("show")
    // ga("send", "pageview", {
    //     "page" : "/credits",
    //     "title" : "PhyloD3 Credits"
    // });
});

// Expand modal: expand all the tree if confirmed
$("#confirm_load").on("click", function(){
    expand_everything(root);
    update(root);
    scale = 0.4;
    source = node_dict["U2'3'4'7'8'9"]
    x = -source.y0;
    y = -source.x0;
    x = x * scale + viewer_width / 2;
    y = y * scale + viewer_height / 2;
    d3.select('g').transition()
        .duration(duration)
        .attr("transform", "translate(" + x + "," + y + ")scale(" + scale + ")");
    zoomListener.scale(scale);
    zoomListener.translate([x, y]);
    $("#expand_modal").modal("hide")
    // ga("send", "event", "button", "click", "Expand all")
});

// Reset modal: Collapse everything but the first 2 levels
// reset the zoom and center
$("#confirm_reset").on("click", function(){
    // Build again from scratch the tree
    // more efficient than iterating over all nodes collapsing them
    baseSvg = d3.select("#tree-container").append("svg")
        .attr("width", viewer_width)
        .attr("height", viewer_height)
        .attr("class", "overlay")
        .call(zoomListener);
    root = json;
    root.x0 = viewer_height / 2;
    root.y0 = 0;
    zoomListener.scale(1)
    duration = 10
    init()
    center_node(node_dict['L0k'])
    duration = 750
    $("#reset_modal").modal("hide")
    // ga("send", "event", "button", "click", "Reset")
});

// Block form submit
$("#autocomplete_form").submit(function(e){
    e.preventDefault();
});

//Cookies law
$(document).ready(function () {
    $.cookieCuttr({
        cookieAnalytics: false,
        cookieMessage: 'We use cookies on this website. To use the website as intended please...'
    });
});


// Raven.config('https://d08b0a41331441dc840a90ebff9e5c95@app.getsentry.com/19048', ravenOptions).install()

// MAIN
d3.json('static/app/tree-definition.json').header("X-Requested-With", "XMLHttpRequest").get(function(error, json_data){
    json = json_data;
    // Tree initial setup and draw
    total_nodes = 0;
    max_label_length = 0;
    // Misc. variables
    i = 0;
    duration = 10;
    node = {};

    node_dict = {};
    root = {};
    tree = {};


    // size of the diagram
    viewer_width = $(document).width();
    viewer_height = $(document).height()-50;

    tree = d3.layout.tree()
        .size([viewer_height, viewer_width]);

    // define a d3 diagonal projection for use by the node paths later on.
    diagonal = d3.svg.diagonal()
        .projection(function(d) {
            return [d.y, d.x];
        });

    // Call visit function to establish max_label_length
    visit(json, function(d) {
        total_nodes++;
        max_label_length = Math.max(d.name.length, max_label_length);
    }, function(d) {
        return d.children && d.children.length > 0 ? d.children : null;
    });

    // define the zoomListener which calls the zoom function on the "zoom" event constrained within the scaleExtents
    zoomListener = d3.behavior.zoom().scaleExtent([0.1, 3]).on("zoom", zoom);

    // define the baseSvg, attaching a class for styling and the zoomListener
    baseSvg = d3.select("#tree-container").append("svg")
        .attr("width", viewer_width)
        .attr("height", viewer_height)
        .attr("class", "overlay")
        .call(zoomListener);

    // Append a group which holds all nodes and which the zoom Listener can act upon.
    svgGroup = baseSvg.append("g");

    // Define the root
    root = json;
    root.x0 = viewer_height / 2;
    root.y0 = 0;

    // Layout the tree initially and center on the root node.
    zoomListener.scale(1)
    update(root)
    init()
    duration = 750
    // Build the node names list needed by autocomplete to work
    var node_names = Object.keys(node_dict).sort()

    // Enable SVG resize
    window.onresize = window_update;

    // AUTOCOMPLETE
    $( "#search" ).autocomplete({
        source: node_names,
        position: { my: "left bottom", at: "left top", collision: "flip" },
        select: function(e, ui){
            var selected_node = node_dict[ui.item.label]
            if (selected_node){
                expand_parents(selected_node)
                // ga("send", "event", "search", "autocomplete", "Search node")

            }
        }
    });
});